export const CcFormat = (value) => {
  if (!value) return "";
  const v = value
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join("  ") : value;
};

export const expirayFormat = (value) => {
  const expdate = value;
  const expDateFormatter =
    expdate.replace(/\//g, "").substring(0, 2) +
    (expdate.length > 2 ? "/" : "") +
    expdate.replace(/\//g, "").substring(2, 4);

  return expDateFormatter;
};
