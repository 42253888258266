import { CardElement } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { STRIPE_SECRET } from "../config";
import axiosServices from "../utils/axios";
import loadingGif from "../images/loading-animation.gif";
import checkGif from "../images/check-animation.png";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../Animation - 1689682343589.json";
import { CcFormat, expirayFormat } from "../utils/format";

function CheckoutForm({ user, priceId }) {
  // collect data from the user
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [name, setName] = useState(`${user?.firstName} ${user?.lastName}`);
  const [email, setEmail] = useState(user.emailAddress);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cVV, setCVV] = useState("");
  const [expiryMonthYear, setExpiryMonthYear] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponToValidate, setCouponToValidate] = useState("");
  const [payableAmount, setPayableAmount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const varToken = query.get("token");

  console.log("priceId", priceId);
  // stripe items

  useEffect(() => {
    if (priceId) {
      const amount = priceId.perMonthPrice * priceId.tenure;
      setPayableAmount(amount);
    }
  }, [priceId]);
  const stripe = require("stripe")(STRIPE_SECRET);
  const updateStripeCustomer = async (paymentMethod) => {
    var customer;
    if (user?.stripCustomerId) {
      customer = await stripe.customers.retrieve(user.stripCustomerId);
      await stripe.paymentMethods.attach(paymentMethod?.id, {
        customer: customer.id,
      });

      await stripe.customers.update(customer.id, {
        invoice_settings: {
          default_payment_method: paymentMethod?.id,
        },
      });
    } else {
      // CREATE CUSTOMER
      customer = await stripe.customers.create({
        email: user.emailAddress,
        name,
        address: {
          line1: addressLine1,
          line2: addressLine2,
          city,
          state,
          postal_code: postalCode,
        },
        payment_method: paymentMethod?.id,
        invoice_settings: {
          default_payment_method: paymentMethod?.id,
        },
      });
    }

    axiosServices.put(
      `/api/customers/update-stripe/${user.id}`,
      {
        stripCustomerId: customer.id,
      },
      {
        headers: {
          Authorization: `Bearer ${varToken}`,
        },
      }
    );

    return customer;
  };

  // main function
  const createSubscription = async () => {
    try {
      setIsSubmitting(true);
      // create a payment method
      const expiryDateBreakup = expiryMonthYear.split("/");
      const paymentMethod = await stripe.paymentMethods.create({
        type: "card",
        card: {
          number: cardNumber.replace(/ /g, ""),
          exp_month: expiryDateBreakup[0],
          exp_year: expiryDateBreakup[1],
          cvc: cVV,
        },
        billing_details: {
          name,
          email: user.emailAddress,
          address: {
            line1: addressLine1,
            line2: addressLine2,
            city,
            state,
            postal_code: postalCode,
          },
        },
      });

      const customer = await updateStripeCustomer(paymentMethod);

      const subsData = {
        customer: customer.id,
        items: [{ price: priceId.stripeProductID }],
        payment_settings: {
          payment_method_options: {
            card: {
              request_three_d_secure: "automatic",
            },
          },
          payment_method_types: ["card"],
          save_default_payment_method: "on_subscription",
        },
        expand: ["latest_invoice.payment_intent"],
      };

      if (coupon) {
        subsData.coupon = coupon;
      }
      const subscription = await stripe.subscriptions.create(subsData);

      console.log("subscription", subscription);

      const { status } = subscription;

      if (status === "incomplete") {
        const { hosted_invoice_url } = subscription.latest_invoice;

        window.location.replace(hosted_invoice_url);
      }
    } catch (error) {
      console.log(error);
      window.alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkCoupon = async () => {
    try {
      setIsLoading(true);
      if (!couponToValidate) throw { message: "Invalid Coupon" };
      if (!priceId) throw { message: "Please select any package" };
      const couponData = await stripe.coupons.retrieve(couponToValidate);
      console.log("couponData", couponData);
      if (couponData?.valid) {
        let amount = priceId.perMonthPrice * priceId.tenure;
        if (couponData?.amount_off) {
          amount = amount - couponData.amount_off / 100;
        } else if (couponData?.percent_off) {
          amount = amount - (amount * couponData.percent_off) / 100;
        }
        setPayableAmount(amount);
        setCoupon(couponData.id);
      }
    } catch (error) {
      window.alert(error.message);
      setCoupon("");
      setCouponToValidate("");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form>
      <div className="flex flex-col gap-3 my-2">
        {/* <input // this should not be a text field. maybe a radio button ro something
        placeholder="Price Id"
        type="text"
        value={priceId}
        onChange={(e) => setPriceId(e.target.value)}
      /> */}
        <input
          placeholder="Name"
          type="text"
          value={name}
          className="border rounded-md p-2"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          placeholder="Email"
          type="text"
          className="border rounded-md p-2"
          value={email}
          readOnly
          // onChange={(e) => setEmail(e.target.value)}
        />
        <input
          placeholder="Address Line 1"
          type="text"
          className="border rounded-md p-2"
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
        />
        <input
          placeholder="Address Line 2"
          type="text"
          className="border rounded-md p-2"
          value={addressLine2}
          onChange={(e) => setAddressLine2(e.target.value)}
        />
        <div className="flex flex-wrap gap-2">
          <input
            placeholder="City"
            type="text"
            className="border rounded-md p-2 w-[48%]"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <input
            placeholder="State"
            type="text"
            className="border rounded-md p-2 w-[48.7%]"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <input
            placeholder="Postal Code"
            type="text"
            className="border rounded-md p-2 w-[48.7%]"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />

          <div class="relative w-[100%]">
            {/* <div class="absolute top-4 left-3">
                 <i class="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>
            </div> */}
            <input
              type="text"
              class="border rounded-md p-2 w-[100%]"
              placeholder="Coupon"
              value={couponToValidate}
              onChange={(e) => {
                setCouponToValidate(e.target.value);
                setCoupon("");
              }}
            />
            <div class="absolute top-2 right-2 flex">
              {/* <Lottie
              options={defaultLottieOptions}
              height={30}
              width={30}
              isStopped={lottieAnimationIsStopped}
              isPaused={lottieAnimationIsPaused}
            /> */}
              {isLoading && (
                <img src={loadingGif} alt="loading" width={28} height={28} />
              )}

              {coupon && (
                <img src={checkGif} alt="checkicon" width={28} height={28} />
              )}

              {!coupon && (
                <button
                  class="w-20 text-white rounded-md opacity-90 hover:opacity-100 bg-[#24949e] hover:bg-[#24949e]"
                  onClick={() => checkCoupon()}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
        </div>
        <input
          placeholder="Card number"
          type="text"
          required
          className="border rounded-md p-2"
          value={CcFormat(cardNumber)}
          maxLength={22}
          onChange={(e) => setCardNumber(e.target.value)}
        />
        <div className="flex gap-2">
          <input
            placeholder="CVV"
            type="text"
            required
            className="border rounded-md p-2 w-[48.7%]"
            maxLength={3}
            value={cVV}
            onChange={(e) => setCVV(e.target.value)}
          />
          <input
            placeholder="Month/Year"
            type="text"
            required
            className="border rounded-md p-2 w-[48.7%]"
            value={expirayFormat(expiryMonthYear)}
            maxLength={5}
            onChange={(e) => setExpiryMonthYear(e.target.value)}
          />
        </div>

        {/* <div className="py-3 mb-2">
        <CardElement />
      </div> */}
        {isSubmitting ? (
          <button
            className="rounded-md border bg-[#24949e] text-white font-medium p-3 my-2 disabled:opacity-50"
            disabled
          >
            Paying...
          </button>
        ) : (
          <button
            className="rounded-md border bg-[#24949e] text-white font-medium p-3 my-2 disabled:opacity-50"
            onClick={createSubscription}
            type="submit"
            disabled={!stripe || !payableAmount}
          >
            Pay {payableAmount && `${payableAmount} kr`}
          </button>
        )}
        <span style={{ textAlign: "center" }}>Terms & Conditions</span>
        <ul style={{ listStyle: "Circle" }} className="text-xs">
          <li>
            Payment will be charged to iTunes Account at confirmation of
            purchase.
          </li>
          <li>
            Subscription automatically renews unless auto-renew is turned off at
            least 24-hours before the end of the current period.{" "}
          </li>
          <li>
            Account will be charged for renewal within 24-hours prior to the end
            of the current period, and identify the cost of the renewal.{" "}
          </li>
          <li>Subscriptions may be managed by the user and auto-ren</li>
        </ul>
        <div style={{display:'flex', justifyContent: "space-between", fontWeight:500 }} className="text-xs">
          <a href="https://halsogourmet.com/terms-conditions/" className="text-[#24949e]">Terms of Use</a>
          <a href="https://halsogourmet.com/privacy-policy/" className="text-[#24949e]">Privacy Policy</a>
        </div>
      </div>
    </form>
  );
}

export default CheckoutForm;
