
import axios from "axios";
import { apibaseUrl } from "../config";
// import { dispatch, globalAction } from '../store';
const axiosServices = axios.create({ baseURL: apibaseUrl, timeout: 5000 });

// interceptor for http
axiosServices.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject((error.response && error.response.data) || "Wrong Services")
);

export default axiosServices;
