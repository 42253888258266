import "./App.css";
import Header from "./components/Header";
import Package from "./components/Package";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import { useEffect, useState } from "react";
import { STRIPE_PUBLISHKEY } from "./config";
import axiosServices from "./utils/axios";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(STRIPE_PUBLISHKEY);
function App() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const varToken = query.get("token");
  const priceId = query.get("priceId");
  const packageId = query.get("packageId");
  const [selectedPriceId, setSelectedPriceId] = useState(priceId || "");
  const [user, setUser] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  // GET USER DETAILS
  useEffect(() => {
    axiosServices
      .get(`/api/customers/me`, {
        headers: {
          Authorization: `Bearer ${varToken}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          setUser(res?.data?.user);
          setError(undefined);
        }
      })
      .catch((error) => {
        setError(error);
        setUser(undefined);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="wrapper">
      {isLoading && <div className="loading p-3 py-5">Loading...</div>}
      {error && (
        <div className="error text-red-800 p-3">
          Session has been expired, please retry!
        </div>
      )}
      {user && (
        <>
          <Header user={user} />
          <Package
            user={user}
            priceId={selectedPriceId}
            setSelectedPriceId={setSelectedPriceId}
          />
          <div className="px-3 pb-2 text-xl font-bold">
            Vänligen fyll i alla detaljer
          </div>
          <div className="px-4">
            <Elements stripe={stripePromise}>
              <CheckoutForm user={user} priceId={selectedPriceId} />
            </Elements>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
