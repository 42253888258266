import checkedIcon from "../icons8-check.svg";
import circleIcon from "../icons8-circle-30.png";
import React, { useEffect, useState } from "react";
import axiosServices from "../utils/axios";

const Package = ({ priceId, setSelectedPriceId }) => {
  const [allPackages, setAllPackages] = useState(undefined);

  useEffect(() => {
    axiosServices.get(`/api/packages/1`).then((res) => {
      if (res?.data?.status === ("Success" || "success")) {
        setAllPackages(res?.data?.data);
      }
    });
  }, []);

  return (
    <div className="packages p-3">
      {allPackages &&
        allPackages?.prices?.map((packageDetails, key) => (
          <div
            className={`cursor-pointer package-card border rounded-lg p-2 mb-3 bg-[#24949e] flex justify-between text-white items-center ${
              priceId && priceId.stripeProductID === packageDetails?.stripeProductID
                ? "shadow-xl opacity-100"
                : "shadow opacity-90"
            }`}
            key={key}
            onClick={() => {
              setSelectedPriceId(packageDetails);
            }}
          >
            <div className="flex items-center gap-2">
              {priceId && priceId.stripeProductID === packageDetails?.stripeProductID ? (
                <div>
                  <img src={checkedIcon} alt="checked" width={20} />
                </div>
              ) : (
                <div>
                  <img
                    src={circleIcon}
                    alt="circle"
                    width={20}
                    height={20}
                    className="opacity-50"
                  />
                </div>
              )}
              <div>
                <h4 className="text-lg font-medium">Premiumpaket</h4>
                <p>För {packageDetails?.tenure} månad</p>
              </div>
            </div>
            <div className="font-medium">
              {Number(packageDetails?.perMonthPrice).toFixed(2)} kr
            </div>
          </div>
        ))}
    </div>
  );
};

export default Package;
