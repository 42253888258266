import Logo from "../Halsogourmet-Logo.png";
import React from "react";

const Header = ({ user }) => {
  return (
    <header className="flex justify-between items-center p-3">
      <div className="logo">
        <img src={Logo} alt="Halsogourmet" width={100} />
      </div>
      <div className="profile text-right">
        <p className="font-semibold">
          {user?.firstName} {user?.lastName}
        </p>
        <p>{user?.emailAddress}</p>
      </div>
    </header>
  );
};

export default Header;
